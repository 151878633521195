import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex';

@Component({
    name: 'GtrRegistrationContentPageView',
    computed: {
        ...mapState('register', ['allContent'])
    }
})
export default class GtrRegistrationContentPageView extends Vue {
    data() {
        return {
            content_pages: [],
            contentPage: null,
            content: ''
        }
    }

    @Watch('allContent', { immediate: true })
    onAllContentChange(payload: any) {  
        if (payload) {
            this.$data.content_pages = payload        
            if (payload.data && payload.data.pages){
                const pages = payload.data.pages
                const pageUuid = this.$route.params.page_uuid
                Object.keys(pages).map((key: any) => {
                    const currentPage: any = pages[key]
                    if (currentPage.uuid === pageUuid) {
                        this.$data.contentPage = (pages)[key]
                        this.$data.content = this.$data.contentPage.page_data.body['en']
                    }
                })
            }
        }
    }
}